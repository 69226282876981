import styled from 'styled-components';

export const Services = styled.section`

    background-color: ${ props => props.theme.greyColor};

    h2 {
        text-align: center;
        font-size: 2rem;
        padding-top: 2rem;
        color: ${ props => props.theme.darkColor };
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        padding: 2rem 2rem 2rem 1rem;

        .service-box {
            padding: 3rem 1rem;
            flex: 1;
            margin-left: 1rem;
            background-color: ${ props => props.theme.lightColor};

            svg {
                font-size: 3rem;
            }

            h3 {
                color: ${ props => props.theme.primaryColor };
                font-size: 1.4rem;
                margin: 0.8rem 0;

            }

            p {
                font-size: 0.8rem;
                color: ${ props => props.theme.darkColor };
            }
        }
    }

    @media (max-width: 768px){

        .container {

            .service-box {
                padding: 3rem 0;

                h3 {
                    font-size: 1.2rem;
                }

                p {
                    font-size: 0.7rem;
                }
            }

        }

    }

    @media (max-width: 500px){

        h2 {
            font-size: 1.7rem;
        }

        .container {

            flex-direction: column;

            .service-box {
                width: 100%;
                margin-left: 0;
                margin-bottom: 0.5rem;
            }

        }

    }

`

export const Processes = styled.section`

    background-color: ${ props => props.theme.lightColor };

    h3 {
        text-align: center;
        font-size: 2rem;
        color: ${ props => props.theme.darkColor };
        padding-top: 2rem;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        padding: 2rem 2rem 2rem 1rem;

        .process-box {
            padding: 3rem 1rem;
            flex: 1;
            margin-left: 1rem;
            flex-basis: 30%;
            margin-bottom: 0.8rem;
            background-color: ${ props => props.theme.greyColor };

            svg {
                font-size: 3rem;
            }

            h4 {
                color: ${ props => props.theme.primaryColor };
                font-size: 1.4rem;
                margin: 0.8rem 0;
            }

            p {
                font-size: 0.8rem;
                color: ${ props => props.theme.darkColor };
            }
        }
    }

    @media (max-width: 768px){

        .container {

            .process-box {
                padding: 3rem 0;

                h3 {
                    font-size: 1.2rem;
                }

                p {
                    font-size: 0.7rem;
                }
            }

        }

    }

    @media (max-width: 500px){

        h3 {
            font-size: 1.7rem;
        }

        .container {

            flex-direction: column;

            .process-box {
                width: 100%;
                margin-left: 0;
                margin-bottom: 0.5rem;
            }

        }

    }

`