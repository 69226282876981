import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faLaptopCode, faMobile, faBullhorn, faDiceOne, faDiceTwo, faDiceThree, faDiceFour, faDiceFive, faDiceSix
} from '@fortawesome/free-solid-svg-icons';
import Header from './../shared/Header';
import { Services, Processes } from './../../elements/ServicesElements.js';

const ServicesContent = () => (
    <>
        <Services>
            <Header page="Prince Rathupa Services" />
            <h2>My <span className="effect">Services</span></h2>
            <div className="container">
                <div className="service-box">
                    <FontAwesomeIcon icon={faLaptopCode} /> 
                    <h3>Web Development</h3>
                    <p>Static - Dynamic - Database Driven</p>
                </div>
                <div className="service-box">
                    <FontAwesomeIcon icon={faBullhorn} /> 
                    <h3>Digital Marketing</h3>
                    <p>SEO - Email - Analytics</p>
                </div>
                <div className="service-box">
                    <FontAwesomeIcon icon={faMobile} /> 
                    <h3>User Experience Design</h3>
                    <p>User Experience - User Interface</p>
                </div>
            </div>
        </Services>
        <Processes>
            <h3>Development <span className="effect">Process</span></h3>
            <div className="container">
                <div className="process-box">
                    <FontAwesomeIcon icon={faDiceOne} /> 
                    <h4>Research</h4>
                    <p>Requirements Gathering - User Research</p>
                </div>
                <div className="process-box">
                    <FontAwesomeIcon icon={faDiceTwo} /> 
                    <h4>Planning</h4>
                    <p>Site map - Tech Stack</p>
                </div>
                <div className="process-box">
                    <FontAwesomeIcon icon={faDiceThree} />  
                    <h4>Design</h4>
                    <p>Wireframe - Mock-up</p>
                </div>
                <div className="process-box">
                    <FontAwesomeIcon icon={faDiceFour} />  
                    <h4>Development</h4>
                    <p>Coding - Protoype</p>
                </div>
                <div className="process-box">
                    <FontAwesomeIcon icon={faDiceFive} />  
                    <h4>Testing</h4>
                    <p>Review - Launch</p>
                </div>
                <div className="process-box">
                    <FontAwesomeIcon icon={faDiceSix} /> 
                    <h4>Support</h4>
                    <p>Monitoring - Updates</p>
                </div>
            </div>
        </Processes>
    </>
)

export default ServicesContent;