import React from "react"
import Layout from './../components/shared/Layout';
import ServicesContent from './../components/services/ServicesContent';

const ServicesPage = () => (
  <Layout title="Services">
      <ServicesContent />
  </Layout>
)

export default ServicesPage;
